<template>
    <v-card class="elevation-2 mt-3">
        <v-card-text class="px-3 px-md-8 pb-5 d-flex align-center justify-center">
            <div style="width: 100%;">
                
                <h1 class="title secondary--text text-center mt-3 mt-md-4 mb-1 mx-auto" style="max-width: 680px;">
                    <v-icon x-large color="primary" class="mb-2">fa fa-user-lock</v-icon>
                    <br>
                    View Emergency Notes
                </h1>

                <v-alert dense type="error" v-if="errors" class="body-2 lh-14 mt-2 mx-auto" style="max-width: 600px;">
                    {{ errors }}
                </v-alert>

                <p class="subtitle-1 mb-4 lh-14 text-center secondary--text mx-auto" style="max-width: 650px;">
                    Provide the last 4 digits of the phone number you received this link on:
                </p>

                <CodeInput :size="4" @updated="last4 = $event" />

                <v-btn color="primary" :loading="authing" v-if="requesting" :disabled="last4.length!=4" class="d-block mt-5 mx-auto" @click.native="authorize()">Unlock Notes</v-btn>
                
                <p class="caption mt-5 px-md-10 mb-0 text-center secondary--text">The information contained herein may be sensitive. <br> For privacy and security, verification of your phone number is required.</p>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
    
export default {
    name: 'AlertAuth',
    props: {
        user: {
            required: false,
            type: Object
        },
        preview: {
            required: false,
            type: Boolean
        }
    },
    components: {
        CodeInput: () => import('@/components/common/CodeInput'),
    },
    computed: {
    },
    data: () => ({
        authing: false,
        errors: null,
        last4: '',
        requesting: true,
        rules: {
            required: value => !!value || 'Required.',
            minlength: value => value && value.length > 5 || 'Must be at least 6 characters.',
        },
    }),
    methods: {
        initData(){
            if(this.preview && this.user.phone){
                this.last4 = this.user.phone.substr(10,4);
            }
        },
        authorize(){ 
            this.authing = true;
            this.errors = null;
            let request = {
                endpoint: '/notes/share/auth/' + this.$route.params.id, 
                method: 'post',
                data: {
                    last4: this.last4,
                    preview: this.user
                },
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200){
                    this.$eventHub.$emit('snackBar', {
                        text: 'Notes have been unlocked!'
                    });
                    this.$emit('authd', res.data);
                }else if(res.status == 404){
                    this.errors = "We could not authenticate you. Please verify you clicked/pasted the correct URL.";
                }else{
                    this.errors = "The number you entered is incorrect. Please try again.";
                }
            }).catch(()=>{
                this.errors = "Something went wrong! Please try again.";
            }).finally(()=>{
                this.authing = false;
            });
        },
    },
    mounted(){
		this.initData();
	},
    watch: {
    }
}
</script>